/* main.css */

:root {
  --primary-color: #4a90e2;
  --secondary-color: #07458b;
  --accent-color: #f39c12;
  --background-color: #f0f4f8;
  --text-color: #333;
  --card-bg-color: #ffffff;
  --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

body.dark-mode {
  --background-color: #1a1a1a;
  --text-color: #f0f0f0;
  --card-bg-color: #2c2c2c;
  --shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--card-bg-color);
  box-shadow: var(--shadow);
}

.logo {
  width: 150px;
  height: auto;
}

nav {
  display: flex;
  gap: 1rem;
}

nav a {
  color: var(--text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

nav a:hover, nav a.active {
  background-color: var(--primary-color);
  color: white;
}

.user-menu {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logout-btn {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: var(--accent-color);
  transition: opacity 0.3s;
}

.logout-btn:hover {
  opacity: 0.8;
}

main {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.sidebar {
  width: 450px;
  padding: 1rem;
  background-color: var(--card-bg-color);
  box-shadow: var(--shadow);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-content {
  flex-grow: 1;
}

.stat-card {
  background-color: var(--card-bg-color);
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: var(--shadow);
}

.stat-card h3 {
  margin: 0 0 0.5rem 0;
  font-size: 0.9rem;
  color: var(--text-color);
}

.feature-icon {
  color: #4a90e2; /* Change this to your preferred color */
  margin-right: 10px;
}

/* You can also add hover effects if desired */
.feature-icon:hover {
  color: #2a70c2; /* A darker shade for hover effect */
}

.plan-info {
  display: inline-flex;
  align-items: center;
  background-color: #4a90e2;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
  margin-right: 10px;
}

.plan-info i {
  margin-right: 5px;
}

.upgrade-link {
  margin-left: 10px;
  color: #ffd700;
  text-decoration: none;
  font-weight: bold;
}

.upgrade-link:hover {
  text-decoration: none;
}

.stat-card p {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary-color);
}

.sidebar-footer {
  margin-top: 2.0rem;
  font-size: 0.9rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.footer-links a {
  color: var(--text-color);
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: none;
}

.copyright {
  font-size: 0.8rem;
  color: var(--text-color);
  opacity: 0.8;
}

.main-content {
  flex-grow: 1;
  padding: 1rem 2rem;
  overflow-y: auto;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.grid-item {
  background-color: var(--card-bg-color);
  padding: 0.75rem;
  border-radius: 10px;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
}

.grid-item h2 {
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.language-select {
  background-color: var(--card-bg-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

.language-select:hover, .language-select:focus {
  background-color: var(--primary-color);
  color: white;
}

/* Style for the dropdown arrow */
.language-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.65em auto;
  padding-right: 1.5em;
}

.language-select::-ms-expand {
  display: none;
}

.grid-item h2 i {
  margin-right: 2px;
  color: var(--primary-color);
}

.avatar-preview {
  grid-column: span 2;
  grid-row: span 2;
}

.avatar-container {
  position: relative;
  width: 100%;
  height: 550px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 1rem;
}

#avatar-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #1a2a6c, #b21f1f, #fdbb2d);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

#avatar {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 1rem;
}

#text {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  resize: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  box-sizing: border-box;
}

.button-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.button-row button {
  flex: 1;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  color: white;
}

.primary-btn {
  background-color: var(--primary-color);
}

.secondary-btn {
  background-color: var(--accent-color);
}

.tertiary-btn {
  background-color: var(--secondary-color);
}

.button-row button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.integration-options {
  display: flex;
  justify-content: flex-start;
  margin-bottom: .5rem;
}

.integration-btn {
  background-color: var(--card-bg-color);
  border: 1px solid var(--primary-color);
  color: var(--text-color);
  padding: 0.5rem 2.5rem;
  border-radius: 5px;
  margin: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.integration-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

.integration-btn .feature-icon {
  margin-right: 5px;
}

#ai-status {
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
  color: var(--text-color);
  font-style: italic;
}

.engagement-metrics,
.sentiment-analysis {
  height: auto;
  min-height: 300px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.engagement-metrics canvas,
.sentiment-analysis canvas {
  flex-grow: 1;
  width: 100% !important;
  height: auto !important;
  max-height: 350px;
}

/* Mock Feature Styles */
.mock-feature-content {
  background-color: var(--card-bg-color);
  border-radius: 10px;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mock-3d-map, .mock-avatar-builder, .mock-3d-chart {
  background-color: #f0f0f0;
  border-radius: 8px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.mock-3d-map img, .mock-avatar-builder img, .mock-3d-chart img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.mock-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.stat {
  text-align: center;
}

.stat-label {
  font-size: 0.8rem;
  color: var(--text-color);
  opacity: 0.7;
}

.stat-value {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary-color);
}

.mock-persona-traits {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.trait {
  background-color: var(--primary-color);
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 15px;
  font-size: 0.8rem;
}

.mock-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 1rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.mock-insights {
  background-color: rgba(80, 227, 194, 0.1);
  border-left: 4px solid #50e3c2;
  padding: 0.5rem;
  margin-top: 1rem;
}

.mock-voice-interface {
  display: flex;
  margin-top: 1rem;
}

.mock-voice-interface input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 5px 0 0 5px;
}

.mock-voice-interface button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 0.5rem;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

/* New styles for enhanced sections */
.feature-list {
  list-style-type: none;
  padding-left: 0;
}

.feature-list li,
.platform-features .feature,
.adaptation-features .feature {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.feature-list li i,
.platform-features .feature i,
.adaptation-features .feature i {
  margin-right: 10px;
  width: 20px;
  text-align: center;
}

.platform-icon {
  display: inline-block;
  margin-right: 15px;
  text-align: center;
}

.ai-metrics {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.metric {
  text-align: center;
}

.metric-value {
  font-size: 1.5em;
  font-weight: bold;
  display: block;
  color: var(--primary-color);
}

/* Dark mode toggle */
#darkModeToggle {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s;
}

#darkModeToggle:hover {
  color: var(--primary-color);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .avatar-preview {
    grid-column: span 2;
    grid-row: auto;
  }
}

@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: flex-start;
  }

  nav {
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  .user-menu {
    margin-top: 1rem;
  }

  main {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    padding: 0.5rem;
  }

  .main-content {
    padding: 0.5rem;
  }

  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .avatar-preview {
    grid-column: auto;
  }

  .grid-item {
    height: auto;
    min-height: 300px;
  }

  .avatar-container {
    height: 300px;
  }

  #text {
    height: 80px;
  }

  .button-row {
    flex-direction: column;
  }

  .button-row button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .mock-stats,
  .platform-features,
  .adaptation-features,
  .ai-metrics {
    flex-direction: column;
    align-items: center;
  }

  .stat,
  .feature,
  .metric {
    margin-bottom: 1rem;
  }
}